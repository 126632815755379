import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"

const ghostPrintOrganicGripsPage = () => {
    const imageComponents = [
        <StaticImage key={1} src="./../../images/ghost-print/ghost-print-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={2} projectName={projectNames.GHOSTPRINT} />,
        <StaticImage key={3} src="./../../images/ghost-print/ghost-print-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/ghost-print/ghost-print-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/ghost-print/ghost-print-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={6} src="./../../images/ghost-print/ghost-print-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="ghostprint" secondHead="organic grips">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="ghostprint organic grips" />
        </PageLayout>
    )
}

export default ghostPrintOrganicGripsPage